import GlobalStateContext from "../../context/globalStateContext"
import React from "react"
import Linkify from "react-linkify"

export default function MoreInfo({ moreInfo, station }) {
  const { DEFAULT_STATION } = React.useContext(GlobalStateContext)

  return (
    <div className="px-4 mt-6 overflow-y-scroll text-sm text-left h-192">
      {moreInfo
        .filter(
          (d) =>
            d.statesOrProvinces.includes("ALL") ||
            d.statesOrProvinces.includes(
              station ? station.state : DEFAULT_STATION.state
            )
        )
        .map((d, i) => {
          return (
            <Linkify key={i} properties={{ target: "_blank" }}>
              <div className="mb-6">
                <h3 className="font-semibold">{d.header}</h3>
                {d.body && <p>{d.body}</p>}
                {d.image && (
                  <img src={d.image} alt="none" className="pb-5 mt-5" />
                )}
              </div>
            </Linkify>
          )
        })}
    </div>
  )
}
