import {
  gddRangeToMsg,
  calculateGdd,
  simpleAve,
  dateRangeToMsg,
} from "../../utils/helpers"
import { format } from "date-fns"

export default function OnionMaggotLogic(modelData, data, dateOfInterest) {
  const { elements, dashboardDisplay, base } = modelData
  const { resultsTable, managementGuide } = elements
  const { generation, peakEmergence } = resultsTable

  const dailyDataWithGdd = calculateGdd(data.dailyData, base, 0, simpleAve).map(
    (d) => {
      return { ...d, dateDisplay: format(d.date, "yyyy-MM-dd") }
    }
  )

  // Determine Management Guide Message ------------------------------------------------
  let selectedDate = dailyDataWithGdd.find(
    (day) => day.dayOfYear === dateOfInterest.dayOfYear
  )
  if (!selectedDate) {
    selectedDate = dailyDataWithGdd.find((d) => d.isForecast)
  }

  const managementGuideMsg = dateRangeToMsg(
    elements.managementGuide.thresholds,
    dateOfInterest
  )
  // console.log(managementGuideMsg)
  ///////////////////////////////////////////////////////////////////////////////////

  // Recalculating degree-days when wild grape bloom ----------------------------------
  const mData = dailyDataWithGdd.map((day) => {
    return {
      date: day.date,
      dateDisplay: day.dateDisplay,
      dayOfYear: day.dayOfYear,
      ms: day.ms,
      dd: day.dd,
      gdd: day.gdd,
      isForecast: day.isForecast,
      generation: gddRangeToMsg(generation, day.gdd),
      peakEmergenceStyle: gddRangeToMsg(peakEmergence, day.gdd),
      empty: null,
      overwintering: null,
      first: null,
      second: null,
    }
  })
  ////////////////////////////////////////////////////////////////////////////////////

  // Data to display in Results Table --------------------------------------------------
  let tableData = [...mData]
  if (mData.length >= 8) {
    tableData = mData.slice(
      dateOfInterest.dayOfYear - 3,
      dateOfInterest.dayOfYear + 5
    )
  }
  //////////////////////////////////////////////////////////////////////////////////////

  // Data in CSV format to export ------------------------------------------------------
  const csvData = mData.map((day) => {
    return {
      Date: day.dateDisplay,
      "Daily DD": day.dd,
      [dashboardDisplay]: day.gdd,
      Generation: day.generation,
    }
  })
  /////////////////////////////////////////////////////////////////////////////////////

  // Dashboard summary ----------------------------------------------------------------
  let dashboard = { managementGuide: { dashboardDisplay: "" }, fromJan1: "" }

  const currentDate = mData.find((d) => d.isForecast)
  if (currentDate) {
    const msg = dateRangeToMsg(managementGuide.thresholds, dateOfInterest)
    if (msg) {
      dashboard.managementGuide = msg
      dashboard.fromJan1 = currentDate.gdd
    }
  }

  return {
    data: mData,
    managementGuideMsg,
    dashboard,
    selectedDate,
    tableData,
    csvData,
  }
}
