import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        The NEWA onion maggot model is based on data and research by Eckenrode
        et al., compiled by Erick Smith and Brian Nault at Cornell University.
      </p>
    </>
  )
}
