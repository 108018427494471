import React from "react";
import ManagementGuide from "../../components/models/managementGuide";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./onion-maggot.json";
import Acknowledgments from "./_acknowledgements";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsGraph from "./_resultsGraph";
import ResultsTable from "./_resultsTable";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

export default function OnionMaggot() {
  const h1Text = modelData.title;
  const { managementGuide } = modelData.elements;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, modelData.seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  let mData = null;
  if (data) {
    mData = modelLogic(modelData, data, dateOfInterest);
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const [showResultsGraph, setShowResultsGraph] = React.useState(true);
  const isVisible = station && mData && !isLoading;

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      geoJSON={geoJSON}
      station={station}
      setStation={setStation}
      allStations={stationList}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      moreInfo={<MoreInfo station={station} moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      showResultsChart={showResultsGraph}
      setShowResultsChart={setShowResultsGraph}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      {/* Management Guide */}
      {isVisible && showManagementGuide && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
            {managementGuide.title}
          </h2>

          <ManagementGuide
            managementGuideMsg={mData.managementGuideMsg}
            setIsMoreInfo={setIsMoreInfo}
          ></ManagementGuide>
        </div>
      )}

      {/* Results Table */}
      {isModelInSeason && isVisible && showResultsTable && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable
            station={station}
            tableData={mData.tableData}
            csvData={mData.csvData}
          ></ResultsTable>
        </div>
      )}

      {/* Results Graph */}
      {isModelInSeason && isVisible && showResultsGraph && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsGraph data={mData.data}></ResultsGraph>
          <p className="px-1 text-sm text-gray-700">
            Peak flights (50% emergence) are indicated by the degree day
            accumulation line intersecting the white dotted lines. The most
            critical time to protect the crop is during the peak emergence of
            the overwintering generation when plants are small. Target larvae
            with insecticide treated seed or an at-planting insecticide drench,
            or protect plants from egg-laying female flies with row covers. Row
            covers must be in place well before adults begin to emerge. Use the
            forecast to predict first emergence.
          </p>
        </div>
      )}
    </ModelLayout>
  );
}
