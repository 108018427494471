import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Andaloro, J.T., Rose, K.B., and Eckenrode, C.J. 1984. Suppressing Onion
        Maggot in Commercial Fields and Research Plots, and Monitoring with Air
        Thermal Unit Accumulations. Search: Agriculture.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Eckenrode, C.J., Vea, E.V., and Stone, K.W. 1975. Population Trends on
        Onion Maggots Correlated with Air Thermal Unit Accumulations.
        Environmental Entomology. 4 (5) pp 785-789.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Smith, E., and Nault, B. 2014. Onion Maggot, Delia antiqua Meigen. New
        York State Integrated Pest Management Program Vegetable Fact Sheet.
      </p>
    </>
  )
}
